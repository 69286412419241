// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OutputPanel-module__outputHeader__In5V6 {
  height: 100%;
}
.OutputPanel-module__outputHeader__In5V6 .ant-tabs.ant-tabs-top {
  height: 100%;
}
.OutputPanel-module__outputHeader__In5V6 .ant-tabs .ant-tabs-tab {
  padding: 13px 16px 14px 16px;
}
.OutputPanel-module__outputHeader__In5V6 .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 1px solid #ebebf2;
}
.OutputPanel-module__outputHeader__In5V6 .ant-tabs-top > .ant-tabs-nav {
  margin: 0;
}
.OutputPanel-module__outputHeader__In5V6 .ant-tabs-content-holder {
  height: 100%;
  overflow: auto;
}
.OutputPanel-module__outputContent__sT3jE {
  padding: 0px 10px;
  width: 100%;
  overflow: auto;
}
.OutputPanel-module__outputContent__sT3jE .facts-table .table-header {
  display: none !important;
}
.OutputPanel-module__outputContent__sT3jE .facts-table .table-body {
  position: initial;
}
.OutputPanel-module__results__ShP03 {
  padding-top: 10px;
}
.OutputPanel-module__emptyOutput__yYPZt {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60%;
  flex-direction: column;
}
.OutputPanel-module__emptyDescription__oVkj3 {
  color: #9292a6;
  text-align: center;
  font-weight: 500;
  width: 60%;
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/PlaygroundV3/components/OutputPanel/OutputPanel.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AAFA;EAGI,YAAA;AAEJ;AALA;EAMI,4BAAA;AAEJ;AARA;EASI,gCAAA;AAEJ;AAXA;EAYI,SAAA;AAEJ;AAdA;EAeI,YAAA;EACA,cAAA;AAEJ;AAEA;EACE,iBAAA;EACA,WAAA;EACA,cAAA;AAAF;AAHA;EAKI,wBAAA;AACJ;AANA;EAQI,iBAAA;AACJ;AAGA;EACE,iBAAA;AADF;AAIA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,sBAAA;AAFF;AAKA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,UAAA;EACA,gBAAA;AAHF","sourcesContent":[".outputHeader {\n  height: 100%;\n  :global(.ant-tabs.ant-tabs-top) {\n    height: 100%;\n  }\n  :global(.ant-tabs .ant-tabs-tab) {\n    padding: 13px 16px 14px 16px;\n  }\n  :global(.ant-tabs-top > .ant-tabs-nav::before) {\n    border-bottom: 1px solid #ebebf2;\n  }\n  :global(.ant-tabs-top > .ant-tabs-nav) {\n    margin: 0;\n  }\n  :global(.ant-tabs-content-holder) {\n    height: 100%;\n    overflow: auto;\n  }\n}\n\n.outputContent {\n  padding: 0px 10px;\n  width: 100%;\n  overflow: auto;\n  :global(.facts-table .table-header) {\n    display: none !important;\n  }\n  :global(.facts-table .table-body) {\n    position: initial;\n  }\n}\n\n.results {\n  padding-top: 10px;\n}\n\n.emptyOutput {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 60%;\n  flex-direction: column;\n}\n\n.emptyDescription {\n  color: #9292a6;\n  text-align: center;\n  font-weight: 500;\n  width: 60%;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outputHeader": `OutputPanel-module__outputHeader__In5V6`,
	"outputContent": `OutputPanel-module__outputContent__sT3jE`,
	"results": `OutputPanel-module__results__ShP03`,
	"emptyOutput": `OutputPanel-module__emptyOutput__yYPZt`,
	"emptyDescription": `OutputPanel-module__emptyDescription__oVkj3`
};
export default ___CSS_LOADER_EXPORT___;
